<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div class="tableBox">
        <div>
          <van-button @click="search()">搜索</van-button>
          <van-button @click="getdataList()">搜索全部</van-button>
          <van-button @click="reSend()">send = false</van-button>
          <van-button @click="setError()">error = true</van-button>
          <van-button @click="setDone()">done = true</van-button>
        </div>
        <div style="text-align: right">总行数： {{dataList.length}}</div>
        <div style="width: 600px; margin: 20px auto">
          <van-pagination force-ellipses @change="getShowList" v-model="dataPage.currentPage" :total-items="dataList.length" :items-per-page="dataPage.pageSize" />
        </div>
        <table>
          <thead>
            <tr>
              <th>time</th>
              <th>hash</th>
              <th>blockNumber</th>
              <th>direction</th>
              <th>amount</th>
              <th>sender</th>
              <th>recipient</th>
              <th>send</th>
              <th>done</th>
              <th>doneTime</th>
              <th>withdraw</th>
              <th>withdrawTime</th>
              <th>error</th>
              <th>errorMessage</th>
              <th>MessageID</th>
              <th>sig</th>
              <th>bridgeSign</th>
              <th>transferFromPoolHash</th>
              <th>removeHash</th>
              <th>limit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in showList" :key="item._id + 'r1'">
              <td>
                <span v-if="item.insertTime/1">
                  {{$moment(item.insertTime/1).format('YYYY-MM-DD HH:mm:ss')}}
                </span>
              </td>
              <td  style="word-break: break-all">{{ item._id }}</td>
              <td>{{ item.blockNumber }}</td>
              <td>
                <span style="color: orange" v-if="item.direction == '1'">
                  DeeperChain to ETH
                </span>
                <span style="color: blue" v-if="item.direction == '2'">
                  ETH to DeeperChain
                </span>
              </td>
              <td>
                {{(item.amount/1e18).toFixed(5)/1}}
              </td>
              <td  style="word-break: break-all">
                <span v-if="item.direction == '1'">
                  {{encodeDeeperChain(item.substrateSender)}}
                </span>
                <span v-else>
                  {{item.sender}}
                </span>
              </td>
              <td  style="word-break: break-all">
                <span v-if="item.direction == '2'">
                  {{encodeDeeperChain(item.recipient)}}
                </span>
                <span v-else>
                  {{item.recipient}}
                </span>
              </td>
              <td>
                <van-icon v-if="item.send" size="20" color="green" name="checked" />
                <van-icon v-else size="20" color="orangered" name="clear" />
              </td>
              <td>
                <van-icon v-if="item.done" size="20" color="green" name="checked" />
                <van-icon v-else size="20" color="orangered" name="clear" />
              </td>
              <td>
                <span v-if="item.doneTime/1">
                  {{$moment(item.doneTime/1).format('YYYY-MM-DD HH:mm:ss')}}
                </span>
              </td>
              <td>
                <van-icon v-if="item.withdraw" size="20" color="green" name="checked" />
              </td>
              <td>
                <span v-if="item.withdrawTime/1">
                  {{$moment(item.withdrawTime/1).format('YYYY-MM-DD HH:mm:ss')}}
                </span>
              </td>
              <td>
                <van-icon v-if="item.isError" size="20" color="orangered" name="clear" />
              </td>
              <td style="color: orangered">{{item.errorMessage}}</td>
              <td  style="word-break: break-all">{{item.MessageID}}</td>
              <td  style="word-break: break-all">{{item.sig}}</td>
              <td  style="word-break: break-all">{{item.bridgeSign}} - {{decodeBridgeSign(item)}}</td>
              <td  style="word-break: break-all">{{item.transferFromPoolHash}}</td>
              <td  style="word-break: break-all">{{item.removeHash}}</td>
              <td>
                <div v-if="item.limit">
                  <div style="white-space: nowrap">today: {{(item.limit.todayTotal / 1e18).toFixed(5)/1}}</div>
                  <div style="white-space: nowrap">erc20: {{(item.limit.todayErc20 / 1e18).toFixed(5)/1}}</div>
                  <div style="white-space: nowrap">deeperChain: {{(item.limit.todayDeeperChain / 1e18).toFixed(5)/1}}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tableBox">
        <div>总行数： {{notWithdrawList.length}} 总量: {{getTotalAmount(notWithdrawList)}}</div>
        <table>
          <thead>
            <tr>
              <th>time</th>
              <th>hash</th>
              <th>blockNumber</th>
              <th>direction</th>
              <th>amount</th>
              <th>sender</th>
              <th>recipient</th>
              <th>send</th>
              <th>done</th>
              <th>doneTime</th>
              <th>withdraw</th>
              <th>withdrawTime</th>
              <th>error</th>
              <th>errorMessage</th>
              <th>MessageID</th>
              <th>sig</th>
              <th>bridgeSign</th>
              <th>transferFromPoolHash</th>
              <th>removeHash</th>
              <th>limit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in notWithdrawList" :key="item._id + 'r1'">
              <td>
                <span v-if="item.insertTime/1">
                  {{$moment(item.insertTime/1).format('YYYY-MM-DD HH:mm:ss')}}
                </span>
              </td>
              <td  style="word-break: break-all">{{ item._id }}</td>
              <td>{{ item.blockNumber }}</td>
              <td>
                <span style="color: orange" v-if="item.direction == '1'">
                  DeeperChain to ETH
                </span>
                <span style="color: blue" v-if="item.direction == '2'">
                  ETH to DeeperChain
                </span>
              </td>
              <td>
                {{(item.amount/1e18).toFixed(5)/1}}
              </td>
              <td  style="word-break: break-all">
                <span v-if="item.direction == '1'">
                  {{encodeDeeperChain(item.substrateSender)}}
                </span>
                <span v-else>
                  {{item.sender}}
                </span>
              </td>
              <td  style="word-break: break-all">
                <span v-if="item.direction == '2'">
                  {{encodeDeeperChain(item.recipient)}}
                </span>
                <span v-else>
                  {{item.recipient}}
                </span>
              </td>
              <td>
                <van-icon v-if="item.send" size="20" color="green" name="checked" />
                <van-icon v-else size="20" color="orangered" name="clear" />
              </td>
              <td>
                <van-icon v-if="item.done" size="20" color="green" name="checked" />
                <van-icon v-else size="20" color="orangered" name="clear" />
              </td>
              <td>
                <span v-if="item.doneTime/1">
                  {{$moment(item.doneTime/1).format('YYYY-MM-DD HH:mm:ss')}}
                </span>
              </td>
              <td>
                <van-icon v-if="item.withdraw" size="20" color="green" name="checked" />
              </td>
              <td>
                <span v-if="item.withdrawTime/1">
                  {{$moment(item.withdrawTime/1).format('YYYY-MM-DD HH:mm:ss')}}
                </span>
              </td>
              <td>
                <van-icon v-if="item.isError" size="20" color="orangered" name="clear" />
              </td>
              <td style="color: orangered">{{item.errorMessage}}</td>
              <td  style="word-break: break-all">{{item.MessageID}}</td>
              <td  style="word-break: break-all">{{item.sig}}</td>
              <td  style="word-break: break-all">{{item.bridgeSign}} - {{decodeBridgeSign(item)}} <van-button size="mini" @click="editBridgeSign(item)">edit</van-button></td>
              <td  style="word-break: break-all">{{item.transferFromPoolHash}}</td>
              <td  style="word-break: break-all">{{item.removeHash}} <van-button size="mini" @click="editRemoveHash(item)">edit</van-button></td>
              <td>
                <div v-if="item.limit">
                  <div style="white-space: nowrap">today: {{(item.limit.todayTotal / 1e18).toFixed(5)/1}}</div>
                  <div style="white-space: nowrap">erc20: {{(item.limit.todayErc20 / 1e18).toFixed(5)/1}}</div>
                  <div style="white-space: nowrap">deeperChain: {{(item.limit.todayDeeperChain / 1e18).toFixed(5)/1}}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{balanceList.length}}</div>
        <div style="width: 600px; margin: 20px auto">
          <van-pagination force-ellipses @change="getBalanceShowList" v-model="balancePage.currentPage" :total-items="balanceList.length" :items-per-page="balancePage.pageSize" />
        </div>
        <table>
          <thead>
            <tr>
              <th>time</th>
              <th colspan="2">bridge</th>
              <th colspan="2">deeperChainPool</th>
              <th colspan="2">total</th>
              <th>netTotal</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in balanceShowList" :key="item._id + 'r1'">
              <td>
                <span v-if="item.createdAt">
                  {{$moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}}
                </span>
              </td>
              <td>{{ item.bridge }}</td>
              <td>{{ (item.bridge/1e18).toFixed(5)/1 }}</td>
              <td>{{ item.deeperChainPool }}</td>
              <td>{{ (item.deeperChainPool/1e18).toFixed(5)/1 }}</td>
              <td>{{getTotal(item)}}</td>
              <td>{{(getTotal(item)/1e18).toFixed(5)/1}}</td>
              <td>{{(getTotal(item)/1e18 - getTotalAmount(notWithdrawList)).toFixed(5)/1}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
import ethService from '@/services/eth'
const {
  encodeAddress,
  decodeAddress
} = require('@polkadot/util-crypto');
const {
  hexToU8a,
  u8aToHex
} = require('@polkadot/util');
function encodeDeeperChain(address) {
  return encodeAddress(hexToU8a(address))
}
function decodeDeeperChain(address) {
  return u8aToHex(decodeAddress(address))
}
import BN from 'bn.js'
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      dataList: [],
      showList: [],
      notWithdrawList: [],
      dataPage: {
        currentPage: 1,
        pageSize: 10,
      },
      balancePage: {
        currentPage: 1,
        pageSize: 10,
      },
      balanceList: [],
      balanceShowList: []
    };
  },
  mounted() {
    this.getdataList();
    this.getBalanceList()
  },
  methods: {
    editBridgeSign(item) {
      let value = prompt()
      if (!value) {
        return
      }
      this.$fetch({
        url: "/bridgeAdmin/records/bridgeSign",
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        data: {
          id: item._id,
          value
        }
      }).then((res) => {
      })
    },
    editRemoveHash(item) {
      let value = prompt()
      if (!value) {
        return
      }
      this.$fetch({
        url: "/bridgeAdmin/records/removeHash",
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        data: {
          id: item._id,
          value
        }
      }).then((res) => {
      })
    },
    setDone() {
      let id = prompt()
      if (!id) {
        return
      }
      this.$fetch({
        url: "/bridgeAdmin/records/done",
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          id: id
        }
      }).then((res) => {
      })
    },
    setError() {
      let id = prompt()
      if (!id) {
        return
      }
      this.$fetch({
        url: "/bridgeAdmin/records/error",
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          id: id
        }
      }).then((res) => {
      })
    },
    reSend() {
      let id = prompt()
      if (!id) {
        return
      }
      this.$fetch({
        url: "/bridgeAdmin/records/send",
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          id: id
        }
      }).then((res) => {
      })
    },
    search(type) {
      let data = prompt()
      if (!data) {
        return
      }
      let needDecode = prompt("是否是deeperChain")
      if (needDecode) {
        data = decodeDeeperChain(data)
        console.log(data)
      }
      
      this.$fetch({
        url: "/bridgeAdmin/records/search",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          search: data
        }
      }).then((res) => {
        console.log(res)
        this.dataList = res.data
        this.getShowList()
      })
    },
    getTotalAmount(list) {
      let total = 0
      list.forEach(it => {
        total += it.amount/1
      })
      return (total/1e18).toFixed(5)/1
    },
    decodeBridgeSign(item) {
      if (!item.bridgeSign) {
        return ''
      }
      try {
        return ethService.getSearchWeb3().eth.accounts.recover(ethService.getSearchWeb3().utils.soliditySha3(item.MessageID), item.bridgeSign);
      } catch(e) {

      }
      return '' 
    },
    encodeDeeperChain,
    getTotal(item) {
      return new BN(item.bridge).add(new BN(item.deeperChainPool))
    },
    getShowList() {
      const { currentPage, pageSize } = this.dataPage
      this.showList = this.dataList.filter(it => it).slice((currentPage-1) * pageSize, currentPage * pageSize)
    },
    getBalanceShowList() {
      const { currentPage, pageSize } = this.balancePage
      this.balanceShowList = this.balanceList.filter(it => it).slice((currentPage-1) * pageSize, currentPage * pageSize)
    },
    getdataList() {
      this.$fetch({
        url: "/bridgeAdmin/records",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res)
        this.dataList = res.data
        this.notWithdrawList = res.notWithdrawList || []
        this.getShowList()
      })
    },
    getBalanceList() {
      this.$fetch({
        url: "/bridgeAdmin/balance",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res)
        this.balanceList = res.data
        this.getBalanceShowList()
      })
    }
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    
    th,td {
      padding: 5px 2px;
    }
  }
tbody {
  tr {
    &:nth-child(even) {
      background: #fff
    }
    &:nth-child(odd) {
      background: #f5f5f5
    }
    &:hover {
      background: #eee
    }
    
  }
}
  
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>